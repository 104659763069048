const initState = {
  authResponse: null,
  loading: false,
  error: false,
};

const AuthReducer = (state = initState, action) => {
  return state;
};

export default AuthReducer;
