import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { connect } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { africanData } from '../africandata';

 function LineGraph({malariaData})   {

  const [graphData, setGraphData] = useState([]);
  const [defaultData, setDefaultData] = useState([]);

  let africanDataToUse =[]

  africanData?.data?.forEach(element => {
    africanDataToUse.push( {
        name: element.Period,
        incidencePer1K: element?.value?.cases?.incidence,
      },)
    });

  useEffect(() => {
    setDefaultData(africanDataToUse.reverse())
    updateState();
  }, [malariaData]);

  console.log(" malariaData?.selectedCountryPeriods",malariaData?.selectedCountryPeriods)

  function updateState(){
    let dataToUse =[]
    malariaData?.selectedCountryPeriods?.forEach(element => {
      dataToUse.push( {
        name: element.Period,
        incidencePer1K:element?.value?.cases?.incidence,
        // mortality: element?.value?.death?.mortalityRate===NaN ? 1000 :  element?.value?.death?.mortalityRate*10000,
        // incidence: element?.value?.cases?.incidence*1000 ?? 1000,
        // deaths: element?.value?.death?.death*10
      },)
    });
 
    setGraphData(dataToUse?.reverse())
  }
   
    return (
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={500}
          data={malariaData?.selectedCountryPeriods!==undefined? graphData : defaultData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="incidencePer1K" stroke="#80BC00" activeDot={{ r: 8 }} />
          {/* <Line type="monotone" dataKey="mortality" stroke="#A6228C" />
          <Line type="monotone" dataKey="deaths" stroke="#f8b940" />
          <Line type="monotone" dataKey="incidence" stroke="#80BC00" /> */}
        </LineChart>
      </ResponsiveContainer>
    );
  }

  export default LineGraph;
