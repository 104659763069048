import malariaData from "../../data.json";
import locationsData from "../../locations.json";
import regions from "../../regions.json"

function compareByTitle(a, b) {
  return a < b ? -1 : a > b ? 1 : 0;
}

const sortedJsonArray = locationsData.data.sort(function (a, b) {
  return compareByTitle(a.Title, b.Title);
});

const initState = {
  defaultData: malariaData,
  locations: sortedJsonArray,
  isLoading: false,
  activeCountry: null,
  activePeriod: null,
  // selectedCountryPeriods: [],
  regions: regions,
  countriesByRegion:[]
};

const MalariaDataReducer = (state = initState, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        isLoading: action.loading,
      };

    case "SELECT_COUNTRY":
      const periods = malariaData.data.filter(
        (x) => x.location.CountryName === action.countryName
      );

      return {
        ...state,
        activeCountry: action.CountryName,
        selectedCountryPeriods: periods,
        isLoading: false,
      };
      case "SELECT_REGION":
        const countries = locationsData?.data?.filter(
          (x) => x.Region === action.region
        );
        console.log("countries from region", countries)
  
        return {
          ...state,
          countriesByRegion: countries,
          isLoading: false,
        };
    case "RESET_COUNTRY":
      return {
        ...state,
        activeCountry: null,
        isLoading: false,
      };
    case "SELECT_PERIOD":
      return {
        ...state,
        activePeriod: action.period,
        isLoading: false,
      };

    case "FILTER_DATA":
      console.log("filtering");
      const activeCountryAndPeriod = malariaData.data.find(
        (x) =>
          x.location.CountryName === action.country &&
          x.Period.toString() === action.period
      );

      return {
        ...state,
        activeCountry: activeCountryAndPeriod,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default MalariaDataReducer;
