import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import AuthReducer from './auth_reducer';
import MalariaDataReducer from './malaria_data_reducer';
import thunk from "redux-thunk";
export const store = configureStore({
   reducer: {
      auth: AuthReducer,
      malariadata: MalariaDataReducer
   },
   middleware: [thunk,   ...getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false
   })],
});