import { Component } from "react";
import { Link } from "react-router-dom";

class DataSubmissionPortal extends Component {
  state = {};
  render() {
    return (
      <div>
        <div className="py-12">
          <div class="container">
          <h3 className="h1 text-center my-4">Data Submission Portal</h3>

            <div class="row">
              <div class="col-md-6">
                <img
                  src="images/images/share-data.svg"
                  alt="visualisations illustrations"
                  loading="lazy"
                  width=""
                  height=""
                  class="img-fluid rounded-3"
                />
              </div>
              <div class="col-md-6 d-flex flex-column align-items-end justify-content-center">
                <h1 class="my-5 h1">Share Your Data</h1>
                <p class="text-muted fs-4">
                  Make a meaningful impact by sharing your data through
                  datasheets. Contribute to the collective knowledge on African
                  diseases and events.
                </p>
                <div class="row">
                  <Link
                    to="#"
                    className="btn border-none bg-afroSkyBlue text-white text-hover-white bg-hover-afroCoral my-4 btn-disabled"
                  >
                    Share Datasheets
                  </Link>
                </div>
              </div>
            </div>
            <div class="row flex-row-reverse">
              <div class="col-md-6">
                <img
                  src="images/images/communicate.svg"
                  alt="upload data illustration"
                  loading="lazy"
                  width=""
                  height=""
                  class="img-fluid rounded-3"
                />
              </div>
              <div class="col-md-6 d-flex flex-column align-items-end justify-content-center">
                <h1 class="my-5 h1">Collaborate and Discuss</h1>
                <p class="text-muted fs-4">
                Initiate a conversation with experts and researchers to exchange insights, discuss findings, and foster collaboration in the field of African health.
                </p>
                <div class="row">
                  <Link
                    to="/submission"
                    className="btn border-none bg-afroSkyBlue text-white bg-hover-afroCoral text-hover-white my-4 btn-disabled"
                  >
                    Start a conversation
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DataSubmissionPortal;
