import { Component } from "react";
import QRCodeComponent from "../components/QRCode";

class QRDisplayPage extends Component {
  render() {
    return (
      <div>
        <div className="text-center">
          <h3>Scan QR code to view on Mobile</h3>
        </div>
        <div class="row d-flex align-items-center justify-content-center vh-100">
          <QRCodeComponent></QRCodeComponent>
        </div>
      </div>
    );
  }
}
export default QRDisplayPage;
