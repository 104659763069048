import { Component } from "react";
import { Link } from "react-router-dom";

class Footer extends Component {
  state = {};
  render() {
    return (
      <div>
        <footer class="bg-afroOceanBlue">
          <div class="p-4">
            <div class="container row">
              <div class="col-md-3 footer_1 footer_region">
                <div class="region region-footer-1">
                  <nav
                    role="navigation"
                    aria-labelledby="block-helpandservices-menu"
                    id="block-helpandservices"
                  >
                    <h2 className="text-white footer-title h3">Help and services</h2>

                    <ul >
                      <li className="mb-3">
                        <a
                          className="text-white fs-5"
                          href="https://www.afro.who.int//copyright" 
                          target="_blank"
                          rel="noreferrer"
                        >
                          Copyright
                        </a>
                      </li>
                      <li className="mb-3">
                        <a className="text-white fs-5" href="https://www.who.int/careers" target="_blank">
                          Careers
                        </a>
                      </li>
                      <li  className="mb-3">
                        <a
                          className="text-white fs-5"
                          href="https://www.afro.who.int/privacy-policy"
                          target="_blank"
                          rel="noreferrer"
                          data-drupal-link-system-path="node/4238"
                        >
                          Privacy policy
                        </a>
                      </li>
                      <li  className="mb-3">
                        <a
                          className="text-white fs-5"
                          href="https://www.afro.who.int/sitemap"
                          target="_blank"
                          rel="noreferrer"
                          data-drupal-link-system-path="node/5103"
                        >
                          Sitemap
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              <div class="col-md-3 footer_2 footer_region">
                <div class="region region-footer-2">
                  <nav
                    role="navigation"
                    aria-labelledby="block-contactus-menu"
                    id="block-contactus"
                  >
                    <h2 className="text-white footer-title h3">Contact us</h2>

                    <ul class="text-white">
                      <li class="mb-3">
                        <a className="fs-5 text-white"
                          href="https://www.afro.who.int/about-us/contact-us"
                          target="_blank"
                          rel="noreferrer"
                          data-drupal-link-system-path="node/3565"
                        >
                          General inquiries
                        </a>
                      </li>
                      <li class="last">
                        <a
                          className="fs-5 text-white"
                          href="https://www.afro.who.int/countries"
                          target="_blank"
                          rel="noreferrer"
                          data-drupal-link-system-path="countries"
                        >
                          WHO country offices
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              <div class="col-md-3 footer_3 footer_region">
                <div class="region region-footer-3">
                  <nav
                    role="navigation"
                    aria-labelledby="block-whooffices-menu"
                    id="block-whooffices"
                  >
                    <h2 className="text-white footer-title h3">WHO Offices</h2>

                    <ul class="menu menu--who-offices nav">
                      <li className="mb-3">
                        <a className="text-white  fs-5" href="http://www.emro.who.int/" target="_blank"
                          rel="noreferrer">
                          WHO Eastern Mediterranean Region
                        </a>
                      </li>
                     
                      <br />
                      <li className="mb-3">
                        <a className="text-white fs-5" href="http://www.paho.org/hq/" target="_blank"
                          rel="noreferrer">
                          WHO Region of the Americas
                        </a>
                      </li>
                      <li className="mb-3">
                        <a className="text-white fs-5" href="http://www.searo.who.int/en/" target="_blank"
                          rel="noreferrer">
                          WHO South-East Asia Region
                        </a>
                      </li>
                      <li className="mb-3">
                        <a className="text-white fs-5" href="http://www.wpro.who.int/en/" target="_blank"
                          rel="noreferrer">
                          WHO Western Pacific Region
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              <div class="col-md-3 footer_4 footer_region">
                <div class="region region-footer-4">
                  <nav
                    role="navigation"
                    aria-labelledby="block-followus-menu"
                    id="block-followus"
                  >
                    <h2 className="text-white footer-title h3">Follow us</h2>

                    <ul className="text-white">
                      <li className="mb-3">
                        <a
                          className="text-white fs-5"
                          href="https://twitter.com/WHOAFRO"
                          target="_self"
                        >
                          Twitter
                        </a>
                      </li>
                      <li className="mb-3">
                        <a
                          href="https://www.facebook.com/WHOAFRO/?fref=nf"
                          className="text-white fs-5"
                          target="_self"
                        >
                          Facebook
                        </a>
                      </li>
                      <li className="mb-3">
                        <a
                          href="http://www.youtube.com/user/WHOAfricanRegion"
                          className="fs-5 text-white"
                          target="_self"
                        >
                          YouTube
                        </a>
                      </li>
                      <li className="mb-3">
                        <a
                          href="https://www.afro.who.int/rss-feeds"
                          target="_blank"
                          rel="noreferrer"
                          className="text-white"
                          data-drupal-link-system-path="node/4295"
                        >
                          Rss Feed
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <div className="bg-afroSkyBlue p-3 displayFlex justifyCenter itemsCenter">
              <p className="text-white fs-4">&copy; 2023 WHO | Regional Office for Africa</p>
        </div>
      </div>
    );
  }
}
export default Footer;
