import React from 'react'
import { Link } from 'react-router-dom'

function Telegram() {
  return (
    <div>
           <div className='py-12 my-12'>
            <div className="d-flex justify-center align-items-center">
            <   i class="fa-brands fa-telegram fa-2xl text-center" style={{color: '#229ED9', fontSize: '30px'}}></i>
            </div>
          <h1 className="text-center fs-4 my-4">Want to use our telegram bot? Scan the QR code below</h1>
          
          <div className="d-flex justify-content-center align-items-center">
            <img
                src="images/images/botqrcode.png"
                alt="upload data illustration"
                loading="lazy"
                width=""
                height=""
                class="img-fluid "
              />
          </div>

          <h1 className="text-center fs-4 my-4">Want to use a link instead ? Click <Link to="https://t.me/whoafrobot" className='fw-bold text-hover-afroCoral' target='_blank'>Here</Link></h1>
        </div>
    </div>
  )
}

export default Telegram