import { Component } from "react";
import { Link } from "react-router-dom";

class PortalOptions extends Component {
  state = {};
  render() {
    return (
      <div>
        {/* <WhoHeader /> */}
        
      <div className="py-24">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <img
                src="images/images/analytics.svg"
                alt="visualisations illustrations"
                loading="lazy"
                width=""
                height=""
                class="img-fluid rounded-3"
              />
            </div>
            <div class="col-md-6 d-flex flex-column align-items-end justify-content-center">
              <h1 class="my-5 h1">Explore Africa's health Data</h1>
              <p class="text-muted fs-4">
                Discover comprehensive data on diseases and events across
                Africa. Access valuable insights and statistics to understand
                the region's health landscape from our Data Dissemination
                Portal.
              </p>
              <div class="row">
              <Link to="/landing"                  
                  className="btn border-none bg-afroSkyBlue text-white text-hover-white bg-hover-afroCoral my-4"
                >
                  Explore Health Programme Data
                </Link>
              </div>
            </div>
          </div>
          <div class="row flex-row-reverse">
            <div class="col-md-6">
              <img
                src="images/images/upload-data.svg"
                alt="upload data illustration"
                loading="lazy"
                width=""
                height=""
                class="img-fluid rounded-3"
              />
            </div>
            <div class="col-md-6 d-flex flex-column align-items-end justify-content-center">
              <h1 class="my-5 h1">Empower Healthcare Research in Africa (coming soon)</h1>
              <p class="text-muted fs-4">
                Contribute to the future of healthcare in Africa by uploading
                your data to our Data Hub. Share valuable insights, promote
                research, and drive innovation in the region's healthcare
                landscape.
              </p>
              <div class="row">
              <Link to="#"                 
                  className="btn btn-disabled border-none bg-afroSkyBlue text-white  text-hover-white my-4"
                >
                  Data sharing Portal
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-afroSkyBlue text-white p-4">
        <div class="row">
          <br />
          <div class="col text-center my-4">
            <h2 className="text-white h1">Our impact in numbers</h2>
            <p className="fs-4">
              Explore the following key statistics that highlight our collective
              efforts in advancing medical knowledge and improving health
              outcomes.
            </p>
          </div>
        </div>
        <div class="row text-center">
          <div class="col-6 col-md-3">
            <div class="counter">
              <i class="fa fa-file-lines fa-2x"></i>
              <h2
                class="timer count-title count-number my-2 text-white h2"
              >50+</h2>
              <p class="count-text text-white fs-4">Total data categories</p>
            </div>
          </div>
          <div class="col-6 col-md-3">
            <div class="counter">
              <i class="fa fa-database fa-2x"></i>
              <h2
                class="timer count-title count-number text-white my-2 h2"
              >20k+</h2>
              <p class="count-text text fs-4 ">Total data collected</p>
            </div>
          </div>
          <div class="col-6 col-md-3">
            <div class="counter">
              <i class="fa fa-users fa-2x"></i>
              <h2
                class="timer count-title count-number text-white my-2 h2"
              >3k+</h2>
              <p class="count-text text-white fs-4">Total active users</p>
            </div>
          </div>
          <div class="col-6 col-md-3">
            <div class="counter">
              <i class="fa fa-earth-africa fa-2x"></i>
              <h2
                class="timer count-title count-number text-white my-2 h2"
                data-to="157"
                data-speed="1500"
              >44</h2>
              <p class="count-text text-white fs-4">Contributing Countries</p>
            </div>
          </div>
        </div>
      </div>
      </div>
    );
  }
}

export default PortalOptions;
