import { Component } from "react";
import { Link } from "react-router-dom";

class DataDisseminationPortalOptions extends Component {
  state = {};
  render() {
    return (
      <div>
        <a class="navbar-brand" href="#">
          <img src="images/images/logo-en.svg" width={200} height={100} />
        </a>
        <nav
          class="navbar sticky-top navbar-expand-lg navbar-dark"
          style={{ "background-color": "#3b8cc4" }}
        >
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav">
              <li class="nav-item active">
                <a class="nav-link" href="/">
                  <div
                    style={{
                      color: "#FFFFFF",
                      fontWeight: "bold",
                      fontSize: 15,
                    }}
                  >
                    Home <span class="sr-only">(current)</span>
                  </div>
                </a>
              </li>
              <li class="nav-item active">
                <a class="nav-link" href="#">
                  <div
                    style={{
                      color: "#FFFFFF",
                      fontWeight: "bold",
                      fontSize: 15,
                    }}
                  >
                    Visit WHO Website <span class="sr-only">(current)</span>
                  </div>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">
                  <div
                    style={{
                      color: "#FFFFFF",
                      fontWeight: "bold",
                      fontSize: 15,
                    }}
                  >
                    About  <span class="sr-only">(current)</span>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </nav>
        <div className="text-center">
          <h3>Data Dissemination Portal</h3>
        </div>
        <div class="row d-flex align-items-center justify-content-center vh-100">
          <div class="col-sm-3">
            <Link to="/landing">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">View Data</h5>
                  <div class="text-center">
                    <img
                      src="images/images/view.svg"
                      style={{ height: "160px" }}
                    ></img>
                  </div>
                  <p class="card-text">Click to view Data</p>
                </div>
              </div>
            </Link>
          </div>
          <div class="col-sm-3">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Download Data</h5>
                <div class="text-center">
                  <img
                    src="images/images/download.svg"
                    style={{ height: "160px" }}
                  ></img>
                </div>
                <p class="card-text">Click to download data</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DataDisseminationPortalOptions;
