import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';


function WhoHeader() {
  const [openMobileNav, setOpenMobileNav] = useState(false);
  const [showBanner, setShowBanner] = useState(true);
  let location = useLocation();

  console.log(location.pathname)
  

  useEffect(() => {
    const initialTimer = setTimeout(() => {
      setShowBanner(false); // Hide the banner after 1.5 minutes (90 seconds)
    }, 90000); // 1.5 minutes = 90000 milliseconds

    const periodicTimer = setInterval(() => {
      setShowBanner(true); // Show the banner
      setTimeout(() => {
        setShowBanner(false); // Hide the banner after 1.5 minutes (90 seconds)
      }, 90000); // 1.5 minutes = 90000 milliseconds
    }, 300000); // 5 minutes = 300000 milliseconds

    return () => {
      clearTimeout(initialTimer); // Clear the initial timer when the component unmounts
      clearInterval(periodicTimer); // Clear the periodic timer when the component unmounts
    };
  }, []);

  function closeNav() {
    setOpenMobileNav(false);
  }
  function openNav() {
    setOpenMobileNav(true);
  }
  const navLinks = [
    {
      title: "Data and Evidence",
      linkTo: "https://aho.afro.who.int/",
      subMenu: false,
      id: "04",
      selected: false,
    },
    {
      title: "Data Hub Demo Site",
      linkTo: "",
      subMenu: false,
      id: "05",
      selected: true,
    },
  ];
  return (
    <div className="position-relative">
      <div className={`text-white p-3 bg-afroOceanBlue`}>
        <div className="container text-center fw-bold fs-4">
          <p> THIS IS A DEMO SITE </p>
        </div>
      </div>
      <div
        style={{ zIndex: 900 }}
        className={`bg-afroNavyBlue  position-fixed w-100 vh-100 ${
          openMobileNav ? "d-block d-md-none" : "d-none"
        } `}
      >
        <div className="header position-relative d-flex justify-content-between bg-transparent border-none align-items-center mt-2 p-4">
          <img
            src="images/images/who-afro-logo.svg"
            style={{ height: "50px" }}
            alt=""
          />
          <i
            class="fa-solid fa-x fa-2xl cursor-pointer"
            style={{ color: "#ffffff" }}
            onClick={closeNav}
          ></i>
        </div>

        <div className="d-flex position-relative justify-content-between gap-0 fs-4 text-white mt-4">
          <div
            style={{ border: "0.5px solid #131c20" }}
            className="p-3 w-100 cursor-pointer bg-hover-afroCoral"
          >
            English
          </div>
          <div
            style={{ border: "0.5px solid #131c20" }}
            className="p-3 w-100 cursor-pointer bg-hover-afroCoral"
          >
            Français
          </div>
          <div
            style={{ border: "0.5px solid #131c20" }}
            className="p-3 w-100 cursor-pointer bg-hover-afroCoral"
          >
            Portuguese
          </div>
        </div>

        <div>
          <ul>
            {navLinks.map((navItem) => {
              return (
                <li
                  className={`mx-2 fw-bold bg-hover-afroCoral d-flex align-items-center h-100 p-3 ${
                    navItem.selected ? "bg-afroCoral" : ""
                  } `}
                >
                  <Link
                    className="text-white  text-hover-white"
                    to={navItem.linkTo ? navItem.linkTo : ""}
                    target={navItem.linkTo ? "_blank" : "_self"}
                  >
                    {navItem.title} &nbsp;
                    {navItem.icon}
                    {navItem.subMenu ? (
                      <span>
                        <i className="fa-solid fa-caret-down"></i>
                      </span>
                    ) : (
                      ""
                    )}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="container p-4 d-flex justify-content-end itemsCenter align-items-center">
        <ul>
          <li>
            <a
              href="#"
              className="ml-6 fw-bold text-afroCoral text-hover-afroCoral fs-5"
            >
              English
            </a>
            <a
              href="#"
              className="ml-6 fw-bold text-afroSkyBlue text-hover-afroCoral fs-5"
            >
              Français
            </a>
            <a
              href="#"
              className="ml-6 fw-bold text-afroSkyBlue text-hover-afroCoral fs-5"
            >
              Portuguese
            </a>
          </li>
        </ul>
      </div>

      <div className="bg-white px-4 py-8">
        <div className="container displayFlex justifyBetween itemsCenter">
          <img src="images/images/who-afro-logo.svg" alt="" />
          <div className="d-none d-md-block">
            <div className="input-group mb-3 " style={{ maxWidth: "300px" }}>
              <input
                type="text"
                className="form-control border border-afroSkyBlue"
                placeholder="Search Coming Soon"
                aria-label="Search Coming Soon"
                aria-describedby="button-addon2"
              />
              <button
                className="btn bg-afroSkyBlue bg-hover-afroCoral"
                type="button"
                id="button-addon2"
              >
                <i
                  className="fa-solid fa-magnifying-glass fa-lg"
                  style={{ color: "#ffffff" }}
                ></i>
              </button>
            </div>
          </div>
          <button
            className="bg-afroSkyBlue px-2 py-2 rounded-sm d-block d-md-none"
            onClick={openNav}
          >
            <i class="fa-solid fa-bars fa-2xl" style={{ color: "#ffffff" }}></i>
          </button>
        </div>
      </div>

      <div className="bg-afroSkyBlue h-14 d-flex align-items-center d-none d-md-block px-4">
        <ul className="d-flex align-items-center fs-6 h-100">
          <li className="mx-1 bg-hover-afroCoral d-flex align-items-center h-100 px-1">
            <a className="text-white text-hover-white" href="/">
              <i
                className="fa-solid fa-house fa-xl"
                style={{ color: "#fff" }}
              ></i>
            </a>
          </li>

          {navLinks.map((navItem) => {
            return (
              <li
                className={`mx-2 fw-bold bg-hover-afroCoral d-flex align-items-center h-100 px-4 ${
                  navItem.selected ? "bg-afroCoral" : ""
                } `}
              >
                <Link
                  className="text-white  text-hover-white"
                  to={navItem.linkTo ? navItem.linkTo : ""}
                  target={navItem.linkTo ? "_blank" : "_self"}
                >
                  {navItem.title} &nbsp;
                  {navItem.icon}
                  {navItem.subMenu ? (
                    <span>
                      <i className="fa-solid fa-caret-down"></i>
                    </span>
                  ) : (
                    ""
                  )}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>

      <nav
        class="navbar navbar-expand-lg navbar-dark p-4 d-flex justify-content-between align-items-center"
        style={{ "background-color": "#001333" }}
      >
        <div className="my-2 my-md-0">      

          <Link
            className="text-white  text-hover-afroCoral fw-bold"
            to="/telegram"
            target="_blank"
          >
            Use our Telegram Bot &nbsp;
            <i class="fa-brands fa-telegram fa-xl ml-4"></i>
          </Link>

          {location.pathname === '/dashboard' || location.pathname === '/landing' ? (
            <Link
              className="text-white  text-hover-afroCoral fw-bold ml-12"
              to="/indicators"
            >
              View more indicators &nbsp;
            </Link>
          ) : (
            ""
          )}
        </div>
        <div className="my-2 my-md-0">
        <Link
              className="text-white  text-hover-afroCoral fw-bold"
              to="mailto:info@hisp.org"
            >
              We value your feedback &nbsp;
            </Link>
        </div>
      </nav>
    </div>
  );
}

export default WhoHeader;
