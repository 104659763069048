import LandingPage from "./views/LandingPage";
import NotFound from "./views/NotFound";
import PortalOptions from "./views/PortalOptions";
import { BrowserRouter as Router, useRoutes } from "react-router-dom";
import { DashBoard } from "./views/DashBoard";
import DataDisseminationPortalOptions from "./views/DataDisseminationPortalOptions";
import QRDisplayPage from "./views/QRDisplayPage";
import HIVDashboard from "./views/HIVDashoard";
import MortalityDashboard from "./views/Mortality";
import SDGDashboard from "./views/SDG";
import TBDashboard from "./views/TB";
import ImmunisationDashboard from "./views/Immunisation";
import DataSubmissionPortal from "./views/DataSubmissionPortal";
import Footer from "./components/Footer";
import WhoHeader from "./components/WhoHeader";
import Telegram from "./views/Telegram";
import PowerBi from "./components/PowerBi";

const App = () => {
  let routes = useRoutes([
    { path: "/", element: <PortalOptions /> },
    { path: "/dissemination", element: <DataDisseminationPortalOptions /> },
    { path: "/submission", element: <DataSubmissionPortal /> },
    { path: "/landing", element: <LandingPage /> },
    { path: "/dashboard", element: <DashBoard /> },
    { path: "/mobile", element: <QRDisplayPage /> },
    { path: "/hiv", element: <HIVDashboard /> },
    { path: "/mortality", element: <MortalityDashboard /> },
    { path: "/sdg", element: <SDGDashboard /> },
    { path: "/tb", element: <TBDashboard /> },
    { path: "/immunisation", element: <ImmunisationDashboard /> },
    { path: "/telegram", element: <Telegram /> },
    { path: "/indicators", element: <PowerBi /> },
    { path: "*", element: <NotFound /> },
  ]);

  return routes;
};

const AppWrapper = () => {
  
  return (
    <Router>

      <WhoHeader />
      <div id="main-wrapper">
        <App />
        <Footer />
      </div>
    </Router>
  );
};

export default AppWrapper;
